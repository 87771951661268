import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { CustomerExtended } from '../interfaces'
import { BehaviorSubject, Observable, Subject, throwError } from 'rxjs'
import { catchError, concatMap, finalize, tap } from 'rxjs/operators'
import { environment } from '@environments/environment'
import { Router } from '@angular/router'
import { LoginRequestDto } from '@visa/shared/interfaces'
import { ApiService } from '@shared/services/api.service'

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  error$: Subject<string> = new Subject<string>()
  authorized$: BehaviorSubject<boolean> = new BehaviorSubject(false)

  constructor(private http: HttpClient, private router: Router, private api: ApiService) {}

  get name(): string {
    return `${this.user.first_name} ${this.user.last_name}`
  }

  get user(): CustomerExtended {
    return JSON.parse(localStorage.getItem('user'))
  }

  get isNewCustomer() {
    return JSON.parse(localStorage.getItem('isNewCustomer'))
  }

  setUser(response) {
    if (response && response.user) {
      localStorage.setItem('user', JSON.stringify(response.user))
      if (this.isAuthenticatedDashboard()) {
        this.authorized$.next(true)
      }
    } else {
      localStorage.removeItem('user')
    }
  }

  fetchErrorFromResponse(errorResponse: HttpErrorResponse): string {
    return errorResponse?.error?.error?.alias ?? errorResponse.message
  }

  handleError(errorResponse: HttpErrorResponse) {
    const error = this.fetchErrorFromResponse(errorResponse)
    this.error$.next(error)
    return throwError(error)
  }

  setIsNewCustomer(serviceId: number) {
    localStorage.setItem('isNewCustomer', JSON.stringify(serviceId === environment.serviceId))
  }

  login(user: LoginRequestDto): Observable<any> {
    return this.http.post(`/api/public/v1/users/login/ssn`, { user }).pipe(
      tap(this.setUser.bind(this)),
      concatMap(() => this.api.getActiveLoan()),
      tap(({ loan }) => this.setIsNewCustomer(+loan.service_id)),
      catchError(this.handleError.bind(this))
    )
  }

  logout(): Observable<any> {
    return this.user
      ? this.http
          .post(`${environment.apiHost}/api/v1/users/logout`, {})
          .pipe(finalize(() => this.setUser({ user: null })))
      : new Observable()
  }

  isAuthenticated(): boolean {
    return Boolean(this.user ?? false)
  }

  isAuthenticatedDashboard(): boolean {
    return Boolean(this.user && this.user?.token)
  }

  isSetupRequired(): boolean {
    return Boolean(+this.user?.initial_3D_secure_required)
  }
}
