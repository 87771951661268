// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { Environment } from './interface'

export const environment: Environment = {
  production: false,
  apiHost: 'https://pre-prod.nordcredit.dk',
  appHost: 'https://app-dev.nordcredit.dk',
  companyAddress: 'Carl Jacobsen vej 16 opgang 6, 4.sal',
  companyCity: 'valby',
  companyId: '38003399',
  companyName: 'Nordcredit',
  companyPostalCode: '2500',
  phone: '78793734',
  email: 'kundeservice@nordcredit.dk',
  payments: {
    merchant: 90219401,
  },
  recaptchaKey: '6LcZ-ZwiAAAAANEKC-YSATIUvoqF2KF3nFqrZTen',
  servicesApiHost: 'https://services.lainasto.fi',
  serviceId: 35,
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
