<img ngSrc="/assets/images/mitid.svg" alt="MitID" width="120" height="31" />
<p class="my-3">{{ 'LoginPage.bankidNotice' | translate }}</p>
@if (api.error$ | async; as error) {
<p class="error">{{ error | translate }}</p>
}
<button type="button" [disabled]="submitting" (click)="continue()">
  @if (submitting) {
  <app-loading [showInline]="true" [showSmall]="true"></app-loading>
  } @if (!submitting) {
  {{ 'LoginPage.login' | translate }}
  }
</button>
