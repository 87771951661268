import { BrowserModule } from '@angular/platform-browser'
import { AppRoutingModule } from './app-routing.module'
import { DEFAULT_CURRENCY_CODE, InjectionToken, LOCALE_ID, NgModule } from '@angular/core'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { AppComponent } from './app.component'
import { NgxMaskModule } from 'ngx-mask'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { SharedModule } from '@shared/shared.module'
import { CookieService } from 'ngx-cookie-service'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'

import { registerLocaleData } from '@angular/common'
import localeDa from '@angular/common/locales/da'
registerLocaleData(localeDa, 'da')

import { defineLocale } from 'ngx-bootstrap/chronos'
import { daLocale } from 'ngx-bootstrap/locale'
import { ModalModule } from 'ngx-bootstrap/modal'
import { TooltipModule } from 'ngx-bootstrap/tooltip'
import { NgxCaptchaModule } from 'ngx-captcha'
import { CustomerNavigationModule } from './customer-navigation/customer-navigation.module'
import { StoreModule } from '@ngrx/store'
import { appReducer } from '@store/app.reducer'
import { environment } from '@environments/environment'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
import { NgxGfmoneyLibModule } from '@yuri.karkhachev/ngx-gfmoney-lib'
import { appStore } from '@store/app.state'
import { NgxGfmoneyMessagesModule } from '@yuri.karkhachev/ngx-gfmoney-messages'
import { AuthService } from '@shared/services/auth.service'
defineLocale('da', daLocale)

// istanbul ignore next
export const createTranslateLoader = (http: HttpClient) => new TranslateHttpLoader(http, './assets/i18n/', '.json')

// istanbul ignore next
export const WindowToken = new InjectionToken<Window>('Window object', {
  providedIn: 'root',
  factory: () => window,
})

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BsDatepickerModule.forRoot(),
    CustomerNavigationModule,
    ModalModule.forRoot(),
    SharedModule,
    HttpClientModule,
    NgxCaptchaModule,
    NgxGfmoneyLibModule.forRoot({
      servicesUrl: environment.servicesApiHost,
    }),
    NgxGfmoneyMessagesModule.forRoot({
      allowedFileTypes: 'image/jpeg,image/png,.pdf,.txt',
      apiUrl: `${environment.apiHost}`,
      brand: environment.companyName,
      dateFormat: 'yyyy-MM-dd HH:mm',
      homePagePath: '/dashboard-visa',
    }),
    NgxMaskModule.forRoot(),
    TooltipModule.forRoot(),
    TranslateModule.forRoot({
      defaultLanguage: 'da',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    StoreModule.forRoot({
      [appStore]: appReducer,
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: !environment.production,
    }),
  ],
  providers: [
    AuthService,
    CookieService,
    { provide: LOCALE_ID, useValue: 'da' },
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'kr' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
