import { Injectable } from '@angular/core'
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http'
import {
  AggregatedCustomerData,
  CustomerDetailsResponseDto,
  Invoice,
  Transaction,
  SignupDetails,
  NemidRequest,
  HousingCostVerification,
  HousingCostVerificationData,
  LoanFeesResponse,
  PaymentFreeMonthResult,
  PaymentFreeMonthStatus,
  BankLink,
  BankItem,
  BankIdResponse,
  ServiceNotification,
  PaymentSchedule,
  TransactionData,
} from '../interfaces'
import { catchError, tap } from 'rxjs/operators'
import { Observable, of, Subject, throwError } from 'rxjs'
import { BankLoginMethods } from '../types'
import {
  CreditCardLoanResponse,
  CreditCardResponseDto,
  CreditCardWithdrawResponseDto,
  Customer,
  DashboardDataResponseDto,
  InitTwoFactorAuthResponseDto,
  LoanDocumentResponseDto,
  UnreadMessagesCountResponseDto,
  UserResponse,
} from '@visa/shared/interfaces'

@Injectable()
export class ApiService {
  static MessageVisibleTime = 10000
  error$: Subject<string> = new Subject<string>()
  errorSlot$: Subject<string> = new Subject<string>()
  success$: Subject<string> = new Subject<string>()
  successSlot$: Subject<string> = new Subject<string>()

  constructor(private http: HttpClient) {}

  // istanbul ignore next
  fetchErrorFromResponse(errorResponse: HttpErrorResponse): string {
    return errorResponse?.error?.error?.alias ?? errorResponse.message
  }

  handleError(errorResponse: HttpErrorResponse) {
    // istanbul ignore next
    const error = this.fetchErrorFromResponse(errorResponse)
    this.error$.next(error)
    this.success$.next('')
    setTimeout(() => this.error$.next(''), ApiService.MessageVisibleTime)
    return throwError(error)
  }

  handleErrorSlot(errorResponse: HttpErrorResponse) {
    // istanbul ignore next
    const error = this.fetchErrorFromResponse(errorResponse)
    this.errorSlot$.next(error)
    this.successSlot$.next('')
    setTimeout(() => this.errorSlot$.next(''), ApiService.MessageVisibleTime)
    return throwError(error)
  }

  handleSuccess(success: any) {
    this.error$.next('')
    this.success$.next(success)
    setTimeout(() => this.success$.next(''), ApiService.MessageVisibleTime)
  }

  handleSuccessSlot(success: any) {
    this.errorSlot$.next('')
    this.successSlot$.next(success)
    setTimeout(() => this.successSlot$.next(''), ApiService.MessageVisibleTime)
  }

  /* Dashboard */
  applyToPaymentFreeMonth(): Observable<PaymentFreeMonthResult> {
    return this.http
      .post<PaymentFreeMonthResult>(`/api/v1/dashboard/users/payment-free-month`, {})
      .pipe(catchError(err => this.handleError(err)))
  }

  checkIfCanGetFreeMonth(): Observable<PaymentFreeMonthStatus> {
    return this.http
      .get<PaymentFreeMonthStatus>(`/api/v1/dashboard/users/payment-free-month`)
      .pipe(catchError(err => this.handleError(err)))
  }

  downloadInvoice(id: number, type: string) {
    return this.http.get(`/api/v1/dashboard/invoices/${id}`, {
      params: new HttpParams().set('type', type),
      responseType: 'blob',
    })
  }

  downloadAgreement() {
    return this.http.get(`/api/v1/dashboard/agreement/download`, {
      responseType: 'blob',
    })
  }

  getActiveLoan(): Observable<{ loan: any }> {
    return this.http.get<{ loan: any }>(`/api/v1/dashboard/loans/active`).pipe(catchError(err => this.handleError(err)))
  }

  acceptDirectDebit(): Observable<any> {
    return this.http.patch<any>(`/api/v1/users/self/direct-debit`, {}).pipe(catchError(err => this.handleError(err)))
  }

  getInvoices(invoicesNumber) {
    let params = {}
    if (invoicesNumber) params = { invoicesNumber }
    return this.http.get<Invoice[]>(`/api/v1/dashboard/invoices`, { params })
  }

  getDirectDebitContract() {
    return this.http.get<any>(`/api/v1/dashboard/direct-debit-contract`).pipe(catchError(err => this.handleError(err)))
  }

  getForwardInterest(date: string) {
    return this.http.get<any>(`/api/v1/dashboard/forward-interest`, { params: { date } }).pipe(
      catchError(err =>
        of({
          error: err.error.errors[0],
          interest: -1,
        })
      )
    )
  }

  getUniquePaymentNumber() {
    return this.http.get<any>(`/api/v1/dashboard/unique-payment-number`).pipe(
      catchError(err =>
        of({
          error: err.error.errors[0],
          paymentNumber: '-',
        })
      )
    )
  }

  getDirectDebitLink(): Observable<{ link: string }> {
    return this.http.get<any>(`/api/v1/users/self/payment-service-link`).pipe(catchError(err => this.handleError(err)))
  }

  getServiceNotification(): Observable<ServiceNotification | null> {
    return this.http
      .get<ServiceNotification | null>(`/api/public/v1/notifications/service`)
      .pipe(catchError(err => this.handleError(err)))
  }

  getPaymentFees(amount: number): Observable<LoanFeesResponse> {
    return this.http
      .get<LoanFeesResponse>(`/api/v1/dashboard/loan/fees`, {
        params: new HttpParams().set('amount', String(amount)),
      })
      .pipe(catchError(err => this.handleError(err)))
  }

  getTransactions() {
    return this.http.get<Transaction[]>(`/api/v1/dashboard/transactions`)
  }

  getCustomerDetails() {
    return this.http.get<CustomerDetailsResponseDto>(`/api/v1/dashboard/details`)
  }

  updateProfile(field: string, value: string) {
    return this.http.patch(`/api/v1/users`, { field, value })
  }

  withdraw(amount: number) {
    return this.http.post<any>(`/api/v1/dashboard/withdrawals`, { amount, fastLoan: false }).pipe(
      tap(() => this.handleSuccess('MessagesBag.withdrawSuccess')),
      catchError(err => this.handleError(err))
    )
  }

  getPaymentSchedule(): Observable<PaymentSchedule> {
    return this.http
      .get<PaymentSchedule>(`/api/v1/withdrawals/payment-schedule`)
      .pipe(catchError(err => this.handleError(err)))
  }

  setupPaymentSchedule(accept: boolean): Observable<any> {
    return this.http
      .post<any>(`/api/v1/withdrawals/payment-schedule`, { accept })
      .pipe(catchError(err => this.handleError(err)))
  }

  cancelPayout() {
    return this.http.patch<any>(`/api/v1/dashboard/payout/cancel`, {}).pipe(
      tap(() => this.handleSuccessSlot('HomePage.payoutCancellationSuccess')),
      catchError(err => this.handleErrorSlot(err))
    )
  }

  raiseCreditLimit() {
    return this.http.post<any>(`/api/v1/dashboard/credit-limit-raise`, {}).pipe(
      tap(() => this.handleSuccess('HomePage.creditLimitUpgradeSuccess')),
      catchError(err => this.handleError(err))
    )
  }

  convertLoanToCard() {
    return this.http.patch<any>(`/api/v1/dashboard/loans/convert`, {}).pipe(catchError(err => this.handleError(err)))
  }

  getBankIdLink(): Observable<{ link: string }> {
    return this.http
      .get<{ link: string }>(`/api/public/v1/bankid/auth/link`)
      .pipe(catchError(err => this.handleError(err)))
  }

  verifyBankIdCode(code: string, phone: string = ''): Observable<BankIdResponse> {
    return this.http
      .post<BankIdResponse>(`/api/public/v1/bankid/auth/verify`, {
        code,
        phone,
      })
      .pipe(catchError(err => this.handleError(err)))
  }

  getBanksList(): Observable<BankItem[]> {
    return this.http.get<BankItem[]>(`/api/v1/dashboard/banks`).pipe(catchError(err => this.handleError(err)))
  }

  getBankVerificationLink(bankId: string, callbackUrl: string): Observable<BankLink> {
    return this.http
      .get<BankLink>(`/api/v1/dashboard/banks/${bankId}/link`, {
        params: new HttpParams().set('callbackUrl', callbackUrl),
      })
      .pipe(catchError(err => this.handleError(err)))
  }

  getBankVerificationResults(requisitionId: string): Observable<any> {
    return this.http
      .get<any>(`/api/v1/dashboard/banks/verification`, {
        params: new HttpParams().set('requisition', requisitionId),
      })
      .pipe(catchError(err => this.handleError(err)))
  }

  getPaymentLink(amount: number, redirectUri: string): Observable<any> {
    return this.http
      .get<any>(`/api/v1/dashboard/users/payments/link`, {
        params: new HttpParams().append('amount', amount.toString()).append('redirectUri', redirectUri),
      })
      .pipe(catchError(err => this.handleError(err)))
  }

  /* Flow */
  completeApplication(affiliatesData: string = ''): Observable<any> {
    return this.http
      .patch(`/api/v1/flow/complete`, {
        affiliatesData,
      })
      .pipe(catchError(err => this.handleError(err)))
  }

  getLoanAndCustomerByGuid(guid: string, brokers: boolean = false): Observable<AggregatedCustomerData> {
    let params = {}
    if (brokers) params = new HttpParams().set('brokers', 'true')
    return this.http
      .get<AggregatedCustomerData>(`/api/public/v1/flow/loans/guid/${guid}`, { params })
      .pipe(catchError(err => this.handleError(err)))
  }

  getInternalChecksStatus(): Observable<any> {
    return this.http
      .post<NemidRequest>(`/api/v1/flow/internal-checks`, {})
      .pipe(catchError(err => this.handleError(err)))
  }

  getEskatCheckData(): Observable<any> {
    return this.http.get<NemidRequest>(`/api/v1/flow/eskat/check`).pipe(catchError(err => this.handleError(err)))
  }

  getBankidCheckUrl(method: BankLoginMethods, callback: string): Observable<any> {
    return this.http
      .get<{ url: string }>(`/api/v1/flow/nemid-check/${method}`, {
        params: new HttpParams().set('callback', callback),
      })
      .pipe(catchError(err => this.handleError(err)))
  }

  saveBankidResults(payload: any): Observable<any> {
    return this.http
      .post<any>(`/api/v1/flow/nemid-check/response`, payload)
      .pipe(catchError(err => this.handleError(err)))
  }

  handleBankidVerificationResponse(queryParams): Observable<any> {
    return this.http
      .post<{ url: string }>(`/api/public/v1/flow/user-identity-verification-response`, queryParams)
      .pipe(catchError(err => this.handleError(err)))
  }

  getTransactionsCheckUrl(): Observable<any> {
    return this.http
      .get<{ url: string }>(`/api/v1/flow/bank-transactions/widget`)
      .pipe(catchError(err => this.handleError(err)))
  }

  getTransactionsCheckStatusUrl(userId: string): Observable<{ url: string }> {
    return this.http
      .get<{ url: string }>(`/api/public/v1/flow/bank-transactions/redirect`, {
        params: new HttpParams().set('userId', userId),
      })
      .pipe(catchError(err => this.handleError(err)))
  }

  triggerMonthioRulesCheck(): Observable<any> {
    return this.http.get(`/api/v1/flow/trigger-monthio-rules-check`).pipe(catchError(err => this.handleError(err)))
  }

  updateCustomerDetails(data): Observable<any> {
    return this.http.patch(`/api/v1/flow/users/details`, { data }).pipe(catchError(err => this.handleError(err)))
  }

  updateCardPassword(password: string): Observable<{ user: Customer }> {
    return this.http.patch<any>(`/api/v1/users/self/card`, { password }).pipe(catchError(err => this.handleError(err)))
  }

  renewCustomerDetails(data): Observable<any> {
    return this.http.patch(`/api/v1/dashboard/customers`, { data }).pipe(catchError(err => this.handleError(err)))
  }

  finishLoanPaymentTypeSelection(): Observable<any> {
    return this.http
      .patch(`/api/v1/flow/loans/finish-payment-type-selection`, {})
      .pipe(catchError(err => this.handleError(err)))
  }

  sendVerificationCode(user: SignupDetails, token: string): Observable<any> {
    return this.http
      .post<any>(`/api/public/v1/flow/send-verification-code`, {
        // @ToDo('Enable token and user for Recaptcha check')
        // token,
        user: {
          email: user.email,
          phone: user.phone,
        },
      })
      .pipe(catchError(err => this.handleError(err)))
  }

  userIdentityVerification(user: SignupDetails, method: BankLoginMethods): Observable<any> {
    return this.http
      .post<any>(`/api/public/v1/flow/user-identity-verification/${method}`, {
        user,
      })
      .pipe(catchError(err => this.handleError(err)))
  }

  updateCustomerEarningsComment(comment: string): Observable<HousingCostVerification> {
    return this.http
      .post<any>(`/api/v1/flow/users/proof-earnings-comment`, { comment })
      .pipe(catchError(err => this.handleError(err)))
  }

  selectPaymentType(type: 'normal' | 'fast'): Observable<any> {
    return this.http.post<any>(`/api/v1/withdrawals?type=${type}`, {}).pipe(catchError(err => this.handleError(err)))
  }

  getFastPaymentStatus(): Observable<any> {
    return this.http
      .get<any>(`/api/public/v1/flow/payments/fast-payment`)
      .pipe(catchError(err => this.handleError(err)))
  }

  authUser(link_id: string): Observable<{ user: any; loan: any }> {
    return this.http.post<any>(`/api/public/v1/users/auth`, { link_id }).pipe(catchError(err => this.handleError(err)))
  }

  cancelApp(guid: string): Observable<any> {
    return this.http
      .post<any>(`/api/public/v1/flow/app/cancel`, { guid })
      .pipe(catchError(err => this.handleError(err)))
  }

  housingCostVerification(data: HousingCostVerificationData): Observable<any> {
    return this.http
      .post<any>(`/api/v1/flow/users/housing-cost-verification`, { data })
      .pipe(catchError(err => this.handleError(err)))
  }

  uploadFile(formData: FormData): Observable<any> {
    return this.http
      .post<any>(`/api/v1/flow/users/proof-earnings`, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(catchError(err => this.handleError(err)))
  }

  verifyPhoneCode(user: SignupDetails): Observable<any> {
    return this.http
      .post<any>(`/api/public/v1/flow/phone-verification`, { user })
      .pipe(catchError(err => this.handleError(err)))
  }

  unsubscribeCustomer(guid: string): Observable<any> {
    return this.http
      .patch<any>(`/api/public/v1/flow/customers/unsubscribe`, {
        guid,
      })
      .pipe(
        tap(() => this.handleSuccess('UnsubscribeCustomer.successMessage')),
        catchError(err => this.handleError(err))
      )
  }

  trackCustomerData(cid: string, session: string, affiliateData: string, data: any): Observable<any> {
    data.cid = cid
    data.session = session
    data.affiliate_data = affiliateData
    return this.http.post<any>(`/api/public/v1/track/data`, { data })
  }

  trackCustomerNavigation(session: string, event: object): Observable<any> {
    return this.http.post<any>(`/api/public/v1/track/navigation`, { data: { session, event } })
  }

  // New visa dash

  getDashboardData(): Observable<DashboardDataResponseDto> {
    return this.http
      .get<DashboardDataResponseDto>(`/api/v1/dashboard`)
      .pipe(catchError(err => this.handleErrorSlot(err)))
  }

  getLoanDocuments(): Observable<LoanDocumentResponseDto> {
    return this.http
      .get<LoanDocumentResponseDto>(`/api/v1/dashboard/loans/documents`)
      .pipe(catchError(err => this.handleErrorSlot(err)))
  }

  getLoanDocument(type: string): Observable<Blob> {
    return this.http
      .get(`/api/v1/dashboard/loans/documents/${type}`, {
        responseType: 'blob',
      })
      .pipe(catchError(err => this.handleErrorSlot(err)))
  }

  getCreditCardLoan(): Observable<CreditCardLoanResponse> {
    return this.http.get<CreditCardLoanResponse>(`/api/v1/dashboard/loans/credit-card`)
  }

  getCreditCardDetails(): Observable<CreditCardResponseDto> {
    return this.http
      .get<CreditCardResponseDto>(`/api/v1/dashboard/cards/details`)
      .pipe(catchError(err => this.handleErrorSlot(err)))
  }

  refreshUser() {
    return this.http
      .get<{ isCreditCardEnabled: boolean; user: UserResponse }>(`/api/v1/users/self`)
      .pipe(catchError(err => this.handleError(err)))
  }

  updateProfile2(user: Partial<Customer>) {
    if (user.pin === '') delete user.pin

    return this.http.patch<UserResponse>(`/api/v1/users/self`, { user }).pipe(
      tap(() => this.handleSuccess('MessagesBag.profileUpdated')),
      catchError(err => this.handleError(err))
    )
  }

  cardWithdraw(redirectUri: string, cancellationUri: string): Observable<CreditCardWithdrawResponseDto> {
    return this.http
      .post<CreditCardWithdrawResponseDto>(`/api/v1/withdrawals/credit-card`, {
        cancellationUri,
        redirectUri,
      })
      .pipe(catchError(err => this.handleErrorSlot(err)))
  }

  getTransactions2(offset, limit, from, to, query): Observable<{ total: number; transactions: TransactionData[] }> {
    let params = new HttpParams().set('offset', offset).set('limit', limit)

    if (from) {
      params = params.set('from', from)
    }
    if (to) {
      params = params.set('to', to)
    }
    if (query) {
      params = params.set('query', query)
    }

    return this.http
      .get<{ total: number; transactions: TransactionData[] }>(`/api/v1/transactions`, {
        params,
      })
      .pipe(catchError(err => this.handleError(err)))
  }

  getTransactionsReport(from: string | null = null, to: string | null = null) {
    let params = new HttpParams()

    if (from) {
      params = params.set('from', from)
    }
    if (to) {
      params = params.set('to', to)
    }

    return this.http
      .get(`/api/v1/transactions/report`, {
        params,
        responseType: 'blob',
      })
      .pipe(catchError(err => this.handleError(err)))
  }

  getPreAuthTransactions(): Observable<{ total: number; amount: number; transactions: TransactionData[] }> {
    return this.http
      .get<{ total: number; amount: number; transactions: TransactionData[] }>(`/api/v1/transactions/pre-auth`)
      .pipe(catchError(err => this.handleError(err)))
  }

  getUnreadMessagesNumber(): Observable<UnreadMessagesCountResponseDto> {
    return this.http
      .get<UnreadMessagesCountResponseDto>('/api/v1/messages/unread')
      .pipe(catchError(err => this.handleError(err)))
  }

  initTwoFactorAuth(): Observable<InitTwoFactorAuthResponseDto> {
    return this.http
      .post<InitTwoFactorAuthResponseDto>(`/api/v1/users/2fa-auth-init`, {})
      .pipe(catchError(err => this.handleError(err)))
  }

  verifyTwoFactorAuth(code: string): Observable<any> {
    return this.http.post<any>(`/api/v1/users/2fa-auth-verify`, { code }).pipe(catchError(err => this.handleError(err)))
  }
}
